import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/main.css'


// Vue.mixin({
//   methods: {
//     sendEvent(name) {
//       if (window.ym) {
//         window.ym(xxx,'reachGoal', name)
//         console.log('send event: '+ name)
//       } else {
//         console.log(window.ym)
//       }
//     }    
//   }
// })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
