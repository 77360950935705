import Vue from "vue";
import VueRouter from "vue-router";

import Main from "../views";
import Mobile from "../views/mobile";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Main,
    meta: {
      title: "desktop",
    },
  },
  {
    path: "/mobile",
    component: Mobile,
    meta: {
      title: "mobile",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  routes,
});

export default router;
